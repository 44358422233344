import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError, take, finalize } from 'rxjs/operators';
import { ErrorMsg } from '../../models/errorMsg';

export class ModuleService<T>{

    public bLoadingSalvar: boolean = false;
    public erroMsg: ErrorMsg = null;
    public erroMsgDialog: ErrorMsg = null;
    public lista: T[];
    public totalRecords: any;
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();


    constructor(protected http: HttpClient, public apiUrl, protected fnConverterObjeto: (json: any) => T) { }

    insert(item: any, _url: string = this.apiUrl) {
        return this.http.post(_url, item).
            pipe(take(1), catchError(this.handleError));
    }

    get(_url = this.apiUrl): Observable<T[]> {
        return this.http.get<T[]>(_url).pipe(take(1), map(this.converterObjetos.bind(this)), catchError(this.handleError));
    }

    getOne(_url = this.apiUrl): Observable<T> {
        return this.http.get<T>(_url).pipe(take(1), map(this.converterObjeto.bind(this)), catchError(this.handleError));
    }

    getById(id: number, _url: string = this.apiUrl): Observable<T> {
        return this.http.get<T>(`${_url}/${id}`).
            pipe(take(1), map(this.converterObjeto.bind(this)), catchError(this.handleError));
    }

    update(item: T, _url: string = this.apiUrl) {
        return this.http.put(_url, item, { params: new HttpParams().set('obj', JSON.stringify(item)) }).
            pipe(take(1), catchError(this.handleError));
    }

    delete(id: number, _url: string = this.apiUrl): Observable<any> {
        return this.http.delete(`${_url}/${id}`).
            pipe(take(1), catchError(this.handleError));
    }

    getListaPaginacao(_url = this.apiUrl) {
        return this.http.get(_url).pipe(take(1), catchError(this.handleError));

    }

    getGeneric(_url = this.apiUrl) {
        return this.http.get(_url).pipe(take(1), catchError(this.handleError));

    }


    handleError(error: any): Observable<any> {
        return throwError(error);
    }

    recarregar(t) {
        this.loading = true;
        let campos: any = {};
        campos.globalFilter = t.globalFilter;
        campos.first = t.first;
        let httpParam = new HttpParams({ fromObject: campos }).toString();
        this.getGeneric(`${this.apiUrl}/pesquisa/campos?${httpParam}`).pipe(finalize(() => { 
        this.loading = false; 
        })).subscribe(
            resp => {
                this.lista = this.converterObjetos(resp.lista);
                this.totalRecords = resp.totalRecords;
            },
            error => {
                this.actionError(error);
                this.lista = [];
            }
        );
    }

    /**
  * 
  * @param dialog se o erro for do dialog = true  -- padrão: false
  */
    fecharErro(dialog = false) {
        dialog ? this.erroMsgDialog = null : this.erroMsg = null;
    }

    /**
     * 
     * @param error 
     * @param dialog se o erro for do dialog = true  -- padrão: false 
     */
    actionError(error, dialog = false) {
        let msg = { tipo: ErrorMsg.ERRO, msg: error.statusText };
        dialog ? this.erroMsgDialog = msg : this.erroMsg = msg;
    }

    public converterObjetos(json: any[]): T[] {
        const obj: T[] = [];
        json.forEach(item => obj.push(this.converterObjeto(item)));
        return obj;
    }

    public converterObjeto(json: any): T {
        return this.fnConverterObjeto(json);
    }

    get loading(): boolean {
        let load = false;
        this.loading$.subscribe(a => load = a);
        return load;
    }

    set loading(l: boolean) {
        this.loadingSubject.next(l);
    }

}