import { UntypedFormControl, AbstractControl, UntypedFormGroup, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import * as moment from 'moment';
export class FormValidation {

    constructor() { }

    static requiredDropDown(formControl: AbstractControl) {
        try {
            let value = formControl.value.id;
            if (value == null) {
                return { required: true };
            }
        } catch {
            return { required: true };
        }
        return {};
    }

    static requiredDropDown2(formControl: AbstractControl) {
        try {
            let value = formControl.value.id;
            if (value == 0) {
                return { required: true };
            }
        } catch {
            return { required: true };
        }
        return {};
    }

    static requiredMultiSelectDropDown(formControl: AbstractControl) {
        try {
            let value: any[] = formControl.value;
            if (value.length == 0) {
                return { required: true };
            }
        } catch {
            return { required: true };
        }
        return {};
    }

    static calculadora(group: UntypedFormGroup) {
        if (!group) {
            return {};
        }


    }

    static repetirSenha(group: UntypedFormGroup) {
        let senhaNova = group.get('senhaNova').value;
        let confSenhaNova = group.get('confSenhaNova').value;
        return senhaNova === confSenhaNova ? {} : { naoIguais: true };
    }
    static cpfValid(formControl: UntypedFormControl) {
        const cpf: string = formControl.value.replace(/[^0-9]+/g, '');
        let soma: number = 0;
        let resto: number;
        let valido: boolean;
        if (cpf.length != 11) { return {} };

        if (
            cpf == '00000000000' || cpf == '11111111111' ||
            cpf == '22222222222' || cpf == '33333333333' ||
            cpf == '44444444444' || cpf == '55555555555' ||
            cpf == '66666666666' || cpf == '77777777777' ||
            cpf == '88888888888' || cpf == '99999999999'
        ) {
            valido = false;
        } else {
            for (let i = 1; i <= 9; i++) {
                soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
            }
            resto = (soma * 10) % 11;

            if (resto == 10 || resto == 11) {
                resto = 0;
            }
            if (resto != parseInt(cpf.substring(9, 10))) {
                valido = false;
            } else {
                soma = 0;
                for (let i = 1; i <= 10; i++) {
                    soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
                }
                resto = (soma * 10) % 11;
                if (resto == 10 || resto == 11) {
                    resto = 0;
                }
                valido = resto == parseInt(cpf.substring(10, 11));
            }
        }
        return valido ? {} : { cpf: true };
    }

    static senhaCorresponde(formControl: UntypedFormControl) {
        if (!formControl.root || !(<UntypedFormGroup>formControl.root).controls) {
            return {};
        }
        let confSenhaNova = formControl.value;
        let senhaNova = (<UntypedFormGroup>formControl.root).get('senhaNova').value;
        return senhaNova === confSenhaNova ? {} : { naoIguais: true };
    }
    /**
     * validação quando não aparece o valor, dtInicial e a dtFinal
     * @param group 
     * @returns 
     */
    static valor_dtInicial_dtFinal(group: UntypedFormGroup) {
        const id = group.get('parcela').value.id;
        const valorFixo = group.get('valorFixo').value;
        if (valorFixo) {
            group.get('dtInicial').setErrors(null);
            group.get('dtFinal').setErrors(null);
            group.get('percentual').setErrors(null);
        } else {
            if (id >= 5) {
                group.get('dtInicial').setErrors(null);
                group.get('dtFinal').setErrors(null);
                group.get('valor').setErrors(null);
            } else {
                group.get('percentual').setErrors(null);
            }
        }
        return {};
    }

    static formReqPublico(group: UntypedFormGroup) {
        if (group.get('tipoParte').value == 1) {
            group.get('oab').setErrors(null);
        } else {
            group.get('cpf').setErrors(null);
        }
        return {};
    }

    static valorFixo(group: UntypedFormGroup) {
        const valorFixo = group.get('valorFixo').value.id;
        const valor = group.get('valor').value.id;
        if (valorFixo) {
            group.get('dtInicial').setErrors(null);
            group.get('dtFinal').setErrors(null);
            group.get('percentual').setErrors(null);
            group.get('dtInicialJuros').setErrors(null);
            group.get('taxaJuros').setErrors(null);
            if (valor <= 0) {
                return { require: true };
            }
        }
        return {};
    }



    static dtInicial(formControl: UntypedFormControl) {
        if (!formControl.root || !(<UntypedFormGroup>formControl.root).controls) {
            return {};
        }
        const fieldAte = (<UntypedFormGroup>formControl.root).get('itens.dtFinal');
        if (formControl.value == "" || formControl.value == null) {
            return { required: true };
        }
        let dif = moment(formControl.value).diff(moment(fieldAte.value));
        if (dif > 0) {
            return { crescente: true };
        }

        return {};
    }

    static percentual(formControl: UntypedFormControl) {
        if (!formControl.root || !(<UntypedFormGroup>formControl.root).controls) {
            return {};
        }
        const id = (<UntypedFormGroup>formControl.root).get('itens.parcela').value.id;
        if (id >= 5 && (formControl.value == "" || formControl.value == null)) {
            return { required: true };
        }
        return {};
    }

    static taxaJuros(formControl: UntypedFormControl) {
        if (!formControl.root || !(<UntypedFormGroup>formControl.root).controls) {
            return {};
        }
        const id = (<UntypedFormGroup>formControl.root).get('itens.parcela').value.id;
        const calcularJuros = (<UntypedFormGroup>formControl.root).get('itens.calcularJuros').value;
        const taxaJuros = formControl.value;
        if (taxaJuros <= 0) {
            if (id == 2) {
                return { required: true };
            }
            if (calcularJuros) {
                if (id == 1 || id == 3) {
                    return { required: true };
                }
            }
        }
        return null;
    }

    static dtFinal(formControl: UntypedFormControl) {
        if (!formControl.root || !(<UntypedFormGroup>formControl.root).controls) {
            return {};
        }
        const fieldDe = (<UntypedFormGroup>formControl.root).get('itens.dtInicial');

        if (formControl.value == "" || formControl.value == null) {
            return { required: true };
        }

        let dif = moment(formControl.value).diff(moment(fieldDe.value));
        if (dif < 0) {
            return { decrescente: true };
        }
    }

    static dtFinalReal(formControl: UntypedFormControl) {
        if (moment(formControl.value).diff(moment('1994-07-01')) < 0) {
            return { dtReal: true };
        }
        return {};
    }
    static taxaJuros_dtIncialJuros(group: UntypedFormGroup) {
        try {
            const valorFixo = group.get('valorFixo').value;
            const id = group.get('parcela').value.id;
            const calcularjuros = group.get('calcularJuros').value;
            if (valorFixo) {
                group.get('dtInicialJuros').setErrors(null);
                group.get('taxaJuros').setErrors(null);
            } else {
                if (id != 2) {
                    if (!calcularjuros) {
                        group.get('dtInicialJuros').setErrors(null);
                        group.get('taxaJuros').setErrors(null);
                    }
                }
            }

        } catch (error) {
            group.get('dtInicialJuros').setErrors(null);
            group.get('taxaJuros').setErrors(null);
        }
        return {};
    }

    static dtIncialJuros(formControl: UntypedFormControl) {
        if (!formControl.root || !(<UntypedFormGroup>formControl.root).controls) {
            return {};
        }
        if ((<UntypedFormGroup>formControl.root).get('itens.calcularJuros').value &&
            ((<UntypedFormGroup>formControl.root).get('itens.parcela').value.id == 3 ||
                (<UntypedFormGroup>formControl.root).get('itens.parcela').value.id == 1)) {

            if (formControl.value == "" || formControl.value == null) {
                return { required: true };
            }

            let dif = moment(formControl.value).diff(moment((<UntypedFormGroup>formControl.root).get('itens.dtFinal').value));
            if (dif > 0) {
                return { crescente: true };
            }
        }
        return {};
    }

    static requiredValor(formControl: UntypedFormControl) {
        if (!formControl) {
            return {};
        }
        if (!formControl.root || !(<UntypedFormGroup>formControl.root).controls) {
            return {};
        }
        const id = (<UntypedFormGroup>formControl.root).get('itens.parcela').value.id;
        const vFixo = (<UntypedFormGroup>formControl.root).get('itens.valorFixo');
        let valor: number = formControl.value;
        if (valor <= 0) {
            if (vFixo) {
                return { required: true };
            }
            if (id <= 4) {
                return { required: true };
            } else {
                return {};
            }
        }
        return {};
    }


    static moedaRequired(formControl: UntypedFormControl) {
        if (!formControl) {
            return {};
        }
        if (!formControl.root || !(<UntypedFormGroup>formControl.root).controls) {
            return {};
        }
        let valor: number = formControl.value;
        if (valor <= 0) {
            return { required: true }

        }

        return {};
    }

    static required(formControl: UntypedFormControl) {
        if (!formControl) {
            return {};
        }
        if (!formControl.root || !(<UntypedFormGroup>formControl.root).controls) {
            return {};
        }
        let value: string = formControl.value;
        if (value == null || value.trim() == "") {
            return { required: true }

        }

        return {};
    }


    static getErro(fieldName: string, validadorNome: string, validadorValue?: any) {
        const config = {
            'required': [`${fieldName} é obrigatório(a)`],
            'minlength': [`${fieldName} precisa ter no mínimo ${validadorValue.requiredLength} caracteres`],
            'maxlength': [`${fieldName} precisa ter no máximo ${validadorValue.requiredLength} caracteres`],
            'email': [`${fieldName} é inválido`],
            'crescente': [`Data inicial não pode ser maior que a Data final`],
            'decrescente': [`Data Final não pode ser menor que a Data inicial`],
            'notUnique': [`Login não disponível`],
            'naoIguais': ["senha nova e confirmaçao são diferentes"],
            'cpf': ["CPF inválido"],
            'dtReal': ['Data não pode ser anterior a 01/07/1994']
        };
        return config[validadorNome];
    }

}